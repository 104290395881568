<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">角色列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加年级</el-button>
		</div>
		<el-table :data="list" row-key="id" border>
			<el-table-column align="center" prop="roleName" label="角色名称" />
			<el-table-column align="center" prop="roleCode" label="角色CODE" />
			<el-table-column align="center" prop="roleDesc" label="角色描述" />
			<el-table-column label="操作" align="center">
				<template #default="scope">
					<el-tag v-if="scope.row.tenantCode == '0'">平台</el-tag>
					<el-tag v-if="scope.row.tenantCode == '1'">校级</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template #default="scope">
					<template v-if="scope.row.code == 'code_contributor'">
						<el-button type="success" size="small" @click="ineditinfo(scope.row)">分配分类权限</el-button>
					</template>
					<el-button type="primary" size="small" @click="edititem(scope.row)">修改</el-button>
					<el-button type="danger" size="small" @click="deleteitem(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		roleslist,
		rolesdelete
	} from '../../util/api'
	export default {
		data() {
			return {
				list: null, //菜单列表	
				loading: false
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			deleteitem(row) {
				this.$confirm('是否删除“' + row.name + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					this.loading = true
					rolesdelete(row.id).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			ineditinfo(row) {
				this.$router.push({
					path: '/home/roleseditinfo',
					query: {
						id: row.id
					}
				})
			},
			edititem(row) {
				this.$router.push({
					path: '/home/rolesedit',
					query: {
						id: row.id
					}
				})
			},
			inadd() {
				this.$router.push({
					path: '/home/rolesadd'
				})
			},
			getlist() {
				this.loading = true
				roleslist({
					pageNum: 1,
					pageSize: 9999
				}).then(res => {
					this.list = res.data.list
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
</style>
